import React from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Toolbar,
  Button,
  Slide,
  useScrollTrigger,
  Box,
} from "@material-ui/core"

import theme from "../theme"

const useStyles = makeStyles(theme => ({
  logo: {
    flexGrow: 1,
  },
  menu: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(5),
    textDecoration: "none",
  },
  activeMenu: {
    color: theme.palette.primary,
  },
}))

const HideOnScroll = props => {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const HeaderComponent = props => {
  const classes = useStyles(theme)

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar style={{ background: theme.palette.background.default }}>
          <Toolbar>
            <Link to="/" className={classes.logo}>
              <img
                src={require("../images/uxofeverything.png")}
                alt="logo"
                width="auto"
              />
            </Link>
            <Link
              to="/"
              className={classes.menu}
              activeStyle={{ color: "#045CB6" }}
            >
              Home
            </Link>
            <Link
              to="/articles"
              className={classes.menu}
              activeStyle={{ color: "#045CB6" }}
            >
              Articles
            </Link>
            <Link
              to="/companies"
              className={classes.menu}
              activeStyle={{ color: "#045CB6" }}
            >
              Companies
            </Link>
            <Box ml={5}>
              <Button variant="outlined" onClick={() => navigate("/contact")}>
                Contact Us
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

export default HeaderComponent
